import React from 'react';

import Layout from 'components/Layout';
import SEO from 'components/SEO';
import KinderwunschPosts from 'components/KinderwunschPosts';

const KinderwunschPage: React.FC = () => {
	return (
		<Layout>
			<SEO title="Kinderwunsch" />
			<KinderwunschPosts />
		</Layout>
	);
};

export default KinderwunschPage;
